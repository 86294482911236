import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { Spinner } from '@blueprintjs/core';

@withRouter
@inject("store")
@observer
export default class AuthBack extends Component
{
    state = {"user":false};
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    async componentDidMount()
    {
        const code = new URLSearchParams(this.props.location.search).get('code');
        const session = new URLSearchParams(this.props.location.search).get('session');
        if( code && session )
        {
            const ret = await this.props.store.getUserInfoByMpCode( code, session );
            if( ret && ret.id )
            {
                this.setState({"user":ret});
            }
        }
    }

    render()
    {
        const main = <div className="AuthBox">
        { this.state.user ? <center className="info">请回到电脑点击按钮继续</center>
        : <Spinner></Spinner> }
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}