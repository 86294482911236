import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Intent, Spinner } from '@blueprintjs/core';

function post(path, params, method) {
	method = method || "post"; // Set method to post by default if not specified.

	// The rest of this code assumes you are not using a library.
	// It can be made less wordy if you use one.
	var form = document.createElement("form");
	form.setAttribute("method", method);
	form.setAttribute("action", path);
	//form.setAttribute("target", "_blank");

	for(var key in params) {
		if(params.hasOwnProperty(key)) {
			var hiddenField = document.createElement("input");
			hiddenField.setAttribute("type", "hidden");
			hiddenField.setAttribute("name", key);
			hiddenField.setAttribute("value", params[key]);

			form.appendChild(hiddenField);
		 }
	}

	document.body.appendChild(form);
	form.submit();
}

@withRouter
@inject("store")
@observer
export default class AuthActionBox extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    state = {'action_text':'登入','action_function':'login'};
    
    async componentDidMount()
    {
        // 加载完成后，检测登入状态
        // 如果 token 存在，通过 token 获取当前用户信息
        if( this.props.store.token )
        {
            const { data } = await this.props.store.getUserInfo();
            if( data && data.level && data.level > 0 )
            {
                this.props.store.set_user( data );
                this.setState({'action_text':'授权','action_function':'doauth'});
                // 如果是受信应用，直接授权
                if( this.props.data.autoJump == 1 ) this.doauth();
            }
        }
    }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    async doauth()
    {
        // 读取 state
        const state = window.localStorage.getItem('we_state') || Date.now();

        window.localStorage.removeItem('we_state')
        
        
        // 这里的授权需要带上token
        const url = this.props.store.apibase + 'authorize?' + new URLSearchParams( {'client_id':this.props.data.id,'state':state,'redirect_uri':this.props.data.redirectUri,'scope':'basic','response_type':'code'} ).toString();
        
        // window.location = url;
        post( url, {"token":this.props.store.token} );
    }

    async login()
    {
        this.props.history.push("/login");
    }
    
    render()
    {
        
        return <div className="action">
            <Button large={true} text={this.state.action_text+(this.props.store.user.nickname ? ' · '+this.props.store.user.nickname : '')} icon="shield" onClick={()=>this[this.state.action_function]()}/> 
            { this.state.action_function == 'doauth' && <Button large={true} minimal={true} text="切换账户" onClick={()=>{this.props.store.set_token(null);this.props.history.push("/login")}}/> } 
            

        </div>;
    }
}