import AppState from '../store/AppState';
import AppToaster from './Toaster';


export function toast( string ) {
    AppToaster.show({ "message": string});
}

export function toInt( string )
{
    return parseInt( string , 10 );
}

export function showApiError( data , t )
{
    if( data && data.code && parseInt( data.code , 10 ) !== 0 )
    {
        if( data.args )
            toast( sprintf( t(data.info)  , ...data.args) );
        else
            toast( t(data.message) );   
            
        if( parseInt( data.code , 10 ) === 40301 )
            window.location = '/login';

        return true;    
    }
    else
        return false;
}

export function isApiOk( data )
{
    return parseInt( data.code , 10 ) === 0;
}



export function strip(html)
{
    var doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}

export function isWechat()
{
    return navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger";
}

