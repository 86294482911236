import { observable, action } from "mobx";
import axios from 'axios';

class FtApi
{
    constructor( apibase, store=null )
    {
        this.apibase = apibase;
        this.store = store;
    }

    async get( path , data = null , token = false )
    {
        const ds = path.indexOf('?') === -1 ? '?' : '&';
        const url =  data == null ? this.apibase + path : this.apibase + path + ds + new URLSearchParams( data ).toString(); 
        return this.return_data(await axios.get( url ));
    }

    async post( path , data = null, token = false )
    {
        var params = new URLSearchParams();
        if( data )
            Object.keys( data ).forEach( item => params.append(item, data[item]) );

        if( token )
            params.append("token", token);
        
        return this.return_data(await axios.post( this.apibase + path  , params ));
    }

    return_data( ret )
    {
        if( parseInt(ret.status) == 200 )
        {
            if( ret.data  )
            {
                if( ret.data.code )
                {
                    // 如果是授权错误
                    if( ret.data.code == 20001 )
                    {
                        window.localStorage.removeItem("WAVARS_token");
                        if( window.confirm( "需要登入后继续，是否转向登入页面" )) window.location = "/login";
                    }
                    if( ret.data.code == 0 ) return ret.data.data;
                    
                    return false;
                } 
                else 
                    return ret.data;
            }

            return false;
        }
        return false;  
    }
}

class AppState
{
    @observable appname = '方糖Connect·微信统一登入'; 
    @observable apibase = 'https://weauth.ftqq.com/'; 
    //@observable apibase = 'http://one.ftqq.com:8000/'; 
    @observable token = false; 
    @observable user = false; 
    auth_state_code = 0;
    to_save_vars = ['token','user'];
    
    constructor()
    {
        this.api = new FtApi( this.apibase, this );
        this.load_vars();
    }

    load_vars()
    {
        this.to_save_vars.forEach( v =>
        {
            const ret = window.localStorage.getItem("WAVARS_"+v);
            if( ret ) this[v] = JSON.parse( ret );
        });
    }

    save_vars( field = null )
    {
        const vars_array = field === null ? this.to_save_vars : [field];
        vars_array.forEach( v =>
        {
            window.localStorage.setItem("WAVARS_"+v, JSON.stringify( this[v] ));
        });
    }

    @action set_token( token )
    {
        this.token = token;
        this.save_vars('token');
    }

    @action set_user( user )
    {
        this.user = user;
        this.save_vars('user');
    }

    async get( path , data = null )
    {
        return await this.api.get( path , data, this.token);
    }

    async post( path , data = null )
    {
        return await this.api.post( path , data, this.token);
    }
    
    async getAppInfo( client_id )
    {
        return await this.api.get('guest/appinfo' , {client_id});
    }

    async doAuth( client_id, state , redirect_uri, scope = 'basic' , response_type = 'code'  )
    {
        this.auth_state_code = state;
        //return await this.api.get('authorize' , {client_id,state,redirect_uri,scope,response_type});
        return await this.get('authorize' , {client_id,state,redirect_uri,scope,response_type});
    }

    async getUserInfo()
    {
        return await this.post('guest/selfinfo');
    }

    async getDoodsOrder( ids , uid )
    {
        return await this.post('guest/goods/order',{ids,uid});
    }

    async getQrCode()
    {
        return await this.get('guest/qrcode');
    }

    async getUserInfoByMpCode( code, session )
    {
        return await this.post('mp/login/by/code',{code,session});
    }
}

export default new AppState();