import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { toast } from '../util/Function';
import AuthInfoBox from '../component/AuthInfoBox'; 
import AuthActionBox from '../component/AuthActionBox'; 


@withRouter
@inject("store")
@observer
export default class Auth extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    state = {"appinfo":false};
    
    async componentDidMount()
    {
       // 首先检测 client id 吧
       // console.log( this.props.match.params.client_id )
       const client_id = new URLSearchParams(this.props.location.search).get('client_id');
       const state = new URLSearchParams(this.props.location.search).get('state');

       if( client_id )
       {
            // 获取应用的基本信息
            const { data } = await this.props.store.getAppInfo( client_id );
            if( data  )
            {
                // 获取应用信息成功
                // 写入应用信息，并进行展示
                this.setState({"appinfo":data});
                // 将client_id 写入 localstorage
                window.localStorage.setItem('we_client_id',client_id);
                window.localStorage.setItem('we_state',state);
            }
            else
            {
                // 获取应用信息失败
                toast("错误的client_id，请确认应用信息是否正确");
                //console.log( 'bad' );
            }
       }
    }

    render()
    {
        const main = !this.state.appinfo ? null : <div className="AuthBox">
            <AuthInfoBox data={this.state.appinfo}/>
            <AuthActionBox data={this.state.appinfo}/>
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}