import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { toast, isWechat } from '../util/Function';
import { Spinner, Button } from '@blueprintjs/core';

@withRouter
@inject("store")
@observer
export default class Cart extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    state = {"message":false,"info":false};
    
    async componentDidMount()
    {
        if( !isWechat() )
        {
            toast("请在购物车用微信扫码进入本页面");
            this.setState({"message":"请在购物车用微信扫码进入本页面"}); 
            return false;
        }
        
        // 通过 string 获取信息
        const query = new URLSearchParams(this.props.location.search);
        const ids = query.get('ids').split(',');
        const wa_uid = query.get('wa_uid');
        const redirect = query.get('redirect');

        // 输入检查
        if( !Array.isArray( ids ) || ids.length < 1 )
        {
            toast("错误的 id，请刷新页面重新扫码");
            return false;
        }

        if( parseInt(wa_uid) < 1 )
        {
            toast("错误的 uid 请刷新页面重新扫码");
            return false;
        }

        this.setState({"info":{
            ids,wa_uid,redirect
        }});

        window.setTimeout( ()=>this.buy( ids, wa_uid ) , 1000 );

        
    }

    async buy( ids, wa_uid )
    {
        // 根据 ids 载入商品信息
        const { data } = await this.props.store.getDoodsOrder( ids, wa_uid );

        if( data && data.order_id && data.config )
        {
            const jsinfo = JSON.parse( data.config );
            // console.log( jsinfo );
            if( jsinfo && jsinfo.appId )
            {
                console.log( jsinfo );
                // 通过 js bridge 唤起微信支付
                // 定义回调函数
                let that = this;
                const redirect = that.state.info.redirect;
                // 定义回调函数
                function onBridgeReady() 
                {
                    /*global WeixinJSBridge:true*/
                    /*eslint no-undef: "error"*/
                    WeixinJSBridge.invoke(
                        'getBrandWCPayRequest', {
                            "appId":jsinfo.appId,     //公众号名称，由商户传入     
                            "timeStamp":jsinfo.timeStamp,         //时间戳，自1970年以来的秒数     
                            "nonceStr":jsinfo.nonceStr, //随机串     
                            "package":jsinfo.package,     
                            "signType":"MD5",         //微信签名方式：     
                            "paySign":jsinfo.paySign //微信签名 
                        },
                        async (res)=>{
                        if(res.err_msg == "get_brand_wcpay_request:ok" )
                        {
                            toast("支付成功"+data.order_id);
                            // 这里需要带着订单号触发回调。
                            window.location = redirect + (redirect.indexOf('?') !== -1 ? '&' : '?') + 'order_id=' + data.order_id;
                            //this.setState({"message":});
                        }else
                        {
                            toast(" 支付已取消"+data.order_id);
                            
                            // alert(  redirect + (redirect.indexOf('?') !== -1 ? '&' : '?') + 'order_id=' + data.order_id );
                        }
                        that.setState({"message":"..."}); 
                    }); 
                }

                if (typeof WeixinJSBridge == "undefined")
                {
                    if( document.addEventListener ){
                        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                    }else if (document.attachEvent){
                        document.attachEvent('WeixinJSBridgeReady', onBridgeReady); 
                        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                    }
                }
                else
                {
                    onBridgeReady();
                }
            }
        }
        
        
    }

    render()
    {
        const main = <div className="fullcenter">
        { this.state.message ? <div>
            <div>{this.state.message}</div>
            <div><Button text="重新发起支付" large={true} onClick={()=>this.buy( this.state.info.ids, this.state.info.wa_uid )}/></div>
        </div> : <Spinner/> }
    </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}