import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { Button, Intent, Spinner, Toast } from '@blueprintjs/core';
import { toast } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class Login extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    state = {"code":false};
    
    async componentDidMount()
    {
        // 获取微信扫码登入代码
        const { data } = await this.props.store.getQrCode();
        if( data )
        {
            this.setState( {"code":data} );
            this.props.store.set_token( data.token );
        }
    }

    async check()
    {
        const { data } = await this.props.store.getUserInfo();
        if( data && data.level && data.level > 0 )
        {
            toast("已成功登入，正在返回授权页面");
            const client_id = window.localStorage.getItem('we_client_id');
            if( client_id ) window.localStorage.removeItem('we_client_id');

            this.props.history.push("/auth?client_id="+client_id);
        }
        else
        {
            toast("请先在手机上点击确认链接后再点击此按钮");
        }
    }
    
    render()
    {
        const main = <div className="AuthBox">
        <h2>微信扫码登入</h2>
        {this.state.code && <>
            <div className="QrBox">
                <img src={this.state.code.url} />
                <div className="action">
                <Button large={true} text="扫码并确认后点此继续" icon="mobile-phone" onClick={()=>this.check()}/>
                </div>
            </div>
        </>}
    </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
} 